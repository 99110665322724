$fontOpenSans: FuturaPT, sans-serif;
$fontInter: FuturaPT, sans-serif;
$fontPressStart: FuturaPT, sans-serif;

$appWidth: 428px;
$appHeight: 1096px;
$textShadow: none;
//$textShadow: 4px 0 0 #000, -4px 0 0 #000, 0 4px 0 #000, 0 -4px 0 #000, 4px 4px #000, -4px -4px 0 #000, 4px -4px 0 #000, -4px 4px 0 #000;
$boxShadowPrimaryBtn: 0 10px 15px rgba(0, 0, 0, 0.15);
$borderBtnWhite: 4px solid #FFFFFF;
$borderBtnNone: 4px solid transparent;
$containerWidth: calc(428px * 0.8);

//$backgroundPages: linear-gradient(48.95deg, #00A1FF -0.74%, #07A7FD 6.69%, #1BB9F9 17.61%, #3BD6F2 30.28%, #62F9EA 42.96%);
$backgroundPages: #2E3957;
%backgroundPagesTemplate {
  display: flex;
  justify-content: center;
  max-width: $appWidth;
  margin: auto;
  min-height: 100vh;
  background: $backgroundPages;
  text-align: center;
}

%pageTitleTemplate {
  h1 {
    font-family: $fontPressStart;
    font-weight: 400;
    font-size: 27px;
    line-height: 120%;
    color: #FFFFFF;
    text-shadow: $textShadow;
  }
}
