.searchInputWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(225.01deg, #0491E7 30.36%, #2BA36C 78.58%);;
    border-radius: 50%;
    position: absolute;
    margin: 0 4px;
    width: 40px;
    height: 40px;
    transition: all 0.1s ease 0s;
    cursor: pointer;
    color: rgb(255, 255, 255);
    border: 1px solid #FFFFFF;
  }
  input {
    width: inherit;
    background: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    padding: 13px 1rem 12px 3rem;
    color: #FFFFFF;
    &:focus, &:active {
      border: 1px solid #FFFFFF;
      outline: none;
    }

    display: flex;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
  }
}
