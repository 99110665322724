.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  position: relative;
  h3 {
    margin: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8em;
    line-height: 120%;
    max-width: 88%;
  }
  img {
    position: absolute;
    right: 0;
    top: 0;
  }
  svg {
    stroke-width: 1rem;
  }
}

.content {
  color: #FFFFFF;
  margin: 1.2em 0;
  ul {
    list-style: none;
    padding-left: unset;
    margin-bottom: 2.5rem;
    li {
      display: flex;
      align-items: center;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      margin-bottom: 1em;
      padding: .5em 0;
      font-size: 1rem;
      line-height: 120%;
      span {
        padding: 0 .5em;
      }
      .item {
        border-left: 1px solid #FFFFFF;
      }
      &.selected {
        background: #009DE6;
        border: 1px solid transparent;
        .item {
          font-weight: 700;
        }
      }
      &.right {
        background: #1FA04C;
        border: 1px solid transparent;
        .item {
          font-weight: 700;
        }
      }
      &.wrong {
        background: #E23341;
        border: 1px solid transparent;
        .item {
          font-weight: 700;
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: cover;
  }
}
