@import "src/utils/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: $containerWidth;
  height: 63px;
  margin: 0 auto;
  z-index: 2;
  img {
    object-fit: cover;
  }
  span {
    font-family: $fontPressStart;
    color: #2E3957;
    padding-left: 1rem;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
  }
  @media screen and (max-width: 428px){
    width: 80%;
  }
  .scoreContainer{
    display: flex;
    align-items: center;
  }
}

.burgerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 32px;
  svg {
    font-size: 5rem;
  }
}

.menuItem {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #FFFFFF;
  padding-left: .5em;
  border-bottom: 0.5px solid #FFFFFF;
  &:focus, &:active {
    border-top: unset;
    border-left: unset;
    border-right: unset;
  }
}
.active {
  color: #FFFFFF;
  background: #0F1624;
}
