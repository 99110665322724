@import "src/utils/variables";


.pageWrapper h1 {
    font-size: 25px;
    line-height: 107%;
}
.placeholderWrapper {
  width: 100%;
  input {
    font-family: $fontOpenSans;
    font-size: 16px;
    font-weight: 400;
    width: inherit;
    line-height: 120%;
    padding: 1em;
    border-radius: 24px;
    background: transparent;
    margin-bottom: 1em;
    border: 1px solid #FFFFFF;
    outline: none;
    &:active {
      color: #FFFFFF;
    }
  }
  label {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #FFFFFF;
  }
}
