.object {
  width: fit-content;
  height: fit-content;
  position: absolute;
  -webkit-tap-highlight-color: transparent;

  svg {
    filter: grayscale(1);
    width: inherit;
    height: inherit;
    cursor: pointer;
    &:hover {
      filter: grayscale(.5);
      transform: scale(1.01);
    }
    &.active {
      filter: grayscale(0);
    }
  }
}
