@import "src/utils/variables";

.authWrapper {
  font-family: $fontOpenSans;
  padding: 1rem 0;

  h3 {
    margin: unset;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    color: #FFFFFF;
  }

  form {
    input {
      width: 100%;
      padding: 1em;
      border-radius: 24px;
      background: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
      margin-bottom: 1em;
      border: 1px solid #FFFFFF;
      outline: none;
    }

    p {
      margin-top: 0;
      color: red;
      font-size: 20px;
    }
  }

  button {
    cursor: pointer;
    font-family: $fontOpenSans;
    font-size: 16px;
    line-height: 120%;
    font-weight: 700;
    text-transform: uppercase;

    &:disabled {
      filter: grayscale(1);
      color: rgba(255, 255, 255, .7);
      border-color: transparent;
      cursor: unset;
    }
  }
}

.codeInput {
  margin-top: 2rem;
}

.error {
  padding-bottom: 1em;
  margin-top: 0;
  color: red;
  font-size: 16px;
}
.success {
  margin-top: 5px;
  color: #55ff00;
  font-size: 16px;
}

.emailInfo {
  color: #ffffff;
  margin-bottom: 20px;
  line-height: 1.3rem;
  a{
    cursor: pointer;;
  }
}

.OAuth2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;

  svg {
    margin-right: .5em;
  }
}

.linkLikesBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  padding: 1em;
  margin: 8px 0;
  text-transform: uppercase;
  cursor: pointer;
}

.switcher {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;
  margin-top: 1.5rem;
}

.linkColored {
  cursor: pointer;
  color: orange;
  text-decoration: none;
}
