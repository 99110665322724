@import "src/utils/variables";

.logoBlockWrapper {
  font-family: $fontPressStart;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2, h3 {
    margin: 0;
    filter: opacity(1);
  }

  h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    text-shadow: $textShadow;
    color: #FFFFFF;
    margin-bottom: .5rem;
    margin-top: 2rem;
    white-space: pre;
  }
  @media (max-width: 30em) {
    h2 {
      font-size: 32px;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    text-shadow: $textShadow;
    color: #FFE921;
  }

  p {
    font-family: $fontInter;
    font-size: 20px;
    font-weight: 700;
    /*background: linear-gradient(270deg, #945DD6 -0.77%, #F46737 101.03%);*/
    /*-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
    /* position: absolute;
     bottom: -45px;*/
    color: #FFFFFF;
  }
}

.mainLogoWrapper {
  position: relative;
  height: 195px;
}

.subLogo {
  position: relative;
  bottom: 29px;
}
.mainLogoWrapper img:nth-child(1){
  left: -8px;
}

.mainLogoWrapper img:nth-child(3){
  left: 8px;
}

.subMainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: .5rem 0 2rem;

  img {
    position: absolute;
    right: -55px;
    bottom: -32px;
  }

  p {
    margin: 0;
    font-weight: 400;
  }
}

.btnGroup {
  padding: 30px 0;
  padding-bottom: 60px;
}

@media screen and (max-width: 375px) {
  .mainLogoWrapper {
    margin-top: 1rem;
    height: 100px;
    img{
      max-width: 100px;
    }
  }
  .subMainWrapper {
    padding: 0;
  }
  .btnGroup {
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    button {
      font-size: 20px !important;
    }
  }
  h5 {
    bottom: -56px !important;
  }

  footer {
    position: relative !important;
  }
}


@media (max-width: 350px) {
  .mainLogoWrapper img:nth-child(1){
    display: none;
  }
  
  .mainLogoWrapper img:nth-child(3){
    display: none;
  }
}
