.planeWrapper {
  position: relative;
  width: 100%;
  height: 190px;
}

@keyframes plane {
  0% {
    opacity: 1;
    left: 450px;
    bottom: 600px;
  }
  100% {
    opacity: 1;
    display: none;
    left: -600px;
    bottom: -10px;
  }

}

.plane {
  width: auto;
  position: absolute;
  z-index: 2;
  opacity: 0;
  animation-name: plane;
  animation-duration: 7s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
.text {
  user-select: none;
  position: absolute;
  padding: .5em;
  transform: skew(24deg, -49.4deg) rotate(25deg);
  font-weight: 800;
  font-size: 20px;
  white-space: nowrap;
  text-transform: uppercase;
  border-left: 3px solid black;
  border-top: 1px solid black;
  background: #FFFFFF;
}
