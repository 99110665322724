@import "src/utils/variables";

.pageWrapper {
  @extend %pageTitleTemplate;
  filter: opacity(1);
  h2, h3 {
    font-family: $fontPressStart;
  }
  h2 {
    text-shadow: none;
    font-weight: 700;
    font-size: 39px;
    line-height: 120%;
    color:#FFFFFF
  }
  h3 {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 27px;
    line-height: 120%;
    text-shadow: $textShadow;
  }
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  margin: 2rem 0;
  padding: 0 1rem;
}
