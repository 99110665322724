.tableWrapper {
  width: 100%;
  padding-bottom: 2rem;
  color: #FFFFFF;

  span {
    font-weight: 400;
  }

  table {
    width: inherit;
    border-collapse: collapse;
  }

  th:not(:nth-last-child(1)), td:not(.time, .bowls) {
    text-align: left;
  }

  th:nth-last-child(1),
  th:nth-last-child(2) {
    text-align: right;
  }

  th, td {
    padding: .8em 4px;
  }

  td {
    font-weight: 700;
    line-height: 120%;
    width: 100%;
  }

  td:not(:last-child) {
    font-size: 14px;
  }

  tbody {
    tr {
      border-bottom: 1px solid #FFFFFF;
      vertical-align: initial;

      td:first-child {
        min-width: 41px;

        span {
          margin-right: 15px;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      td:last-child {
        text-align: right;
        width: 10%;
        padding-left: 20px;
      }
    }
  }
}

.isWide {
  th, td {
    font-size: 17px !important;
  }
  th:last-child,td:last-child,th:nth-last-child(2){
    font-size: 18px !important;
    text-align: right;
  }
}

.rank {
  display: flex;
  justify-content: space-between;
}

.name {
  width: 50%;
}

.bowls {
  text-align: right;
}

.time {
  font-size: 16px;
}

.gold, .silver, .bronze {
  object-fit: contain;
}

.active {
  background: #009DE6;
  color: #FFFFFF;
}
