@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
//@import url('./assets/fonts/FuturaPT-Light.ttf');
@font-face {
  font-family: 'FuturaPT';
  src: local('FuturaPT'), url(./assets/fonts/FuturaPT-Medium.ttf) format('truetype');
}
@import "src/utils/variables";

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  touch-action: pan-x pan-y;
  margin: 0;
  font-size: 12px;
  font-family: $fontInter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #363636;

  img {
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
  }

  svg {
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
  }
}
