@import "src/utils/variables";

%btn {
  width: 100%;
  font-family: $fontPressStart;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  border-radius: 80px;
  padding: 1rem 0;
  color: #FFFFFF;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.primary {
  background: #009DE6;
  border: $borderBtnWhite;
  box-shadow: $boxShadowPrimaryBtn;
  @extend %btn;
  &:hover {
    background: transparent;
    border: $borderBtnWhite;
    box-shadow: $boxShadowPrimaryBtn;
  };
  &:active {
    background: linear-gradient(90deg, #925ED6 0%, #15ABC7 100%);
    border: $borderBtnNone;
  }
}
.secondary {
  background: transparent;
  border: $borderBtnWhite;
  //backdrop-filter: blur(16px);
  filter: opacity(1);
  @extend %btn;
  &:hover {
    background: #009DE6;
    border: $borderBtnWhite;
    box-shadow: $boxShadowPrimaryBtn;
  };
  &:active {
    background: #009DE6;
    border: $borderBtnNone;
  }
}
