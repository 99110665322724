@import "src/utils/variables";

.wrapper {
  width: $containerWidth;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3rem;
  top: 0;
  @media screen and (max-width: 428px){
    width: 80%;
  }
}

.closeBtn {
  cursor: pointer;
}
