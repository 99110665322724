.footer {
  padding: .5rem 0;
  filter: opacity(1);
  a {
    text-decoration: none;
  }

  img {
    height: 42px;
    margin: 5px;
  }
}

.bottomFixed {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
a {
  color: #00b7ff;
}
a:active{
  color: #00b7ff;
}
a:hover{
  color: #00b7ff;
}

