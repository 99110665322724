@import "src/utils/variables";

.pageWrapper {
  @extend %pageTitleTemplate;
  filter: opacity(1);
  text-align: left;
  h1 {
    font-family: $fontInter;
    font-size: 28px;
    font-weight: 800;
    line-height: 110%;
    text-shadow: unset;
  }
}
