@import "src/utils/variables";

.mainWrapper {
  @extend %backgroundPagesTemplate;
  position: relative;
  background: url("../../assets/images/bg.png") center no-repeat;
  background-size: cover;
}

.isWide {
  max-width: calc(100vw - 100px) !important;
  margin-top: 5vh;
}

.ramen1 {
  position: absolute;
  left: 0;
  top: 1%;
}

.ramen2 {
  position: absolute;
  right: 0;
  top: -1%;
}

.ramen3 {
  position: absolute;
  left: 0;
  bottom: 0;
  filter: blur(2px);
}

.ramen4 {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 375px){
  .mainWrapper {
    overflow: hidden;
  }
}