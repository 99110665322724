.cascadeImg {
  display: flex;
  justify-content: start;
  position: relative;
  img{
    width: 50px;
    z-index: 2;
  }
  img:not(:first-child) {
    margin-left: -10px;
  }

  .horizontalRow{
    height: 6px;
    background: linear-gradient(88.5deg, #2CA466 32.11%, #0491E7 137.02%);
    transform: matrix(1, 0, -0.58, 1, 0, 0);
    width: 100%;
    position: absolute;
    bottom: 5px;
  }
}
