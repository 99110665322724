.object {
  width: fit-content;
  height: fit-content;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  svg {
    width: inherit;
    height: inherit;
  }
}
