@import "src/utils/variables";

.gameLayout {
  max-width: $appWidth;
  height: $appHeight;
  margin: 0 auto;

  @media screen and (max-width: 428px) {
    width: 100vw;
    height: calc(100vw * 1096 / 428);
  }
}

.preloader {
  position: fixed;
  z-index: 3;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
}
.preloader {
  width: 70px;
  height: 70px;
  border: 5px solid #eee;
  border-top: 5px solid #009DE6;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.background {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/background.jpg") no-repeat top left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.plane {
  position: relative;
  height: 190px;
  width: 100%;
  top: 0
}